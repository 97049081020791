import React from "react"
import { ReactComponent as CheckboxIcon } from "../../assets/img/icons/check-mark.svg"
import { PdfReportItemData } from "../../models/pdfReportData"
import { ReactComponent as CheckIcon } from "../../assets/img/icons/check.svg"
import { PdfItems, ReportStatuses, STATUS_COLOR } from "../../types/constants"
import useTranslate from "../../hooks/useTranslate"

type PropsFromState = {
  data: PdfReportItemData;
  onChangeCb: (data: PdfReportItemData) => void;
  description?: string
};

const CheckboxItem: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {data, onChangeCb, description} = props
  const translateLocalazy = useTranslate()
  const onChangeHandler = () => {
    onChangeCb(data)
  }
  const checkmarkException: Array<string> = [
    PdfItems.SymptomSurveys,
    PdfItems.Cognitive
  ]
  return (<div className="pb-5 margin-me--btm">
      <div className="flex align-center checkbox-modal-item flex--wrap">
        <div className="flex align-center">
          <div className="custom-checkbox_wrapper margin-right flex-item--shrink">
            <input
              checked={data.isSelected}
              onChange={onChangeHandler}
              className="hidden-checkbox"
              type="checkbox"/>
            <div className="pseudo-checkbox_holder"></div>
            <CheckboxIcon className="pseudo-checkbox_icon"/>
          </div>
          <span
            style={{cursor: 'pointer'}}
            className="flex-item--shrink"
            onClick={onChangeHandler}>
                      {translateLocalazy(data.title)}
                  </span>
        </div>
        <div className="status_box flex-item--shrink">
          <div className="status-holder">
            {
              (data.status === ReportStatuses.Complete || checkmarkException.includes(data.type)) &&
                <CheckIcon className="circle-checked"/>
            }
            {data.date &&
                <span className="status-text">{data.date}</span>
            }
            {data.status !== ReportStatuses.Complete &&
                <span className="status-text">&nbsp;{translateLocalazy(data.status)}</span>
            }
            {data.status && !checkmarkException.includes(data.type) &&
                <span className={`circle circle--${STATUS_COLOR[data.status]}`}></span>
            }
            {checkmarkException.includes(data.type) &&
                <span className="circle circle--green"></span>
            }
          </div>
        </div>
      </div>
      {description && <span className="text-sm">{description}</span>}
    </div>
  )
}

export default CheckboxItem
