import React, { useCallback, useState } from 'react'
import { translate } from '../../../../selectors/translations'
import { formatDate } from '../../../../utils/datetime'
import PatientSideHistoryLayout from "../PatientSideHistoryLayout"
import { QuestionerType, ReportExportHistoryDto, ReportExportMetadataDto } from "../../../../sdk"
import { generateInteractor } from "../../util"
import './PatientSidePDFSection.scss'
import { PatientProfileState } from "../../../../store/PatientProfile/types"
import useTranslate from "../../../../hooks/useTranslate"
import dayjs from "dayjs"
import { cognitivePdfParams, symptomSurveyPdfParams } from "../../../../types/constants"
import { getRelativeDegree, getRelativeTitle } from "../../../../components/FamilySurveyCard/FamilySurveyCard"

type PropsFromState = {
  patientProfile: PatientProfileState;
  downloads: ReportExportHistoryDto[];
}

const PatientSidePDFSection: React.FC<PropsFromState> = (props: PropsFromState): React.ReactElement => {
  const {downloads, patientProfile} = props
  const allAnswers = patientProfile.questionerAnswers
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const interactor = generateInteractor()
  const translateLocalazy = useTranslate()

  const handleExpandClick = useCallback((): void => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  const getTranslatedAction = (actionData: ReportExportMetadataDto) => {
    if (actionData && actionData.reportForPatient) return translate('sentToPatient')
    return translate('downloaded')
  }

  return (
    <PatientSideHistoryLayout
      isExpanded={isExpanded}
      handleExpandClick={handleExpandClick}
      title={translate('reportExport')}
    >
      {isExpanded &&
          <div className="card_body">
            {downloads.length
              ? downloads
                .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
                .map((downloadInfo, i) =>
                  (
                    <article key={i} className="card_comment-box" style={{padding: '5px'}}>
                      <div className="comment_header">
                        <div className="comment_header_title collapse justify-between align-center full-width">
                          <div className="flex-item flex-item--shrink">
                            <span>{interactor(downloadInfo.interactor)} {getTranslatedAction(downloadInfo.metadata)}</span>
                            <br/>
                            {downloadInfo.metadata.exportedAnswers &&
                                <div className="download-pdf-title pdf-export-details">
                                  {downloadInfo.metadata.exportedAnswers.map((e, i) => {
                                    const answer = allAnswers.find(a => a.id === e.answer)!
                                    let answerTitle = ''
                                    let sections = e.sections
                                    switch (answer.questioner.kind) {
                                      case QuestionerType.ANAMNESIS:
                                        answerTitle = translateLocalazy('Anamnesis')
                                        sections = e.sections.map(s => translateLocalazy(`questioner.anamnesis.section.${s}`))
                                        break
                                      case QuestionerType.COGNITIVE:
                                        answerTitle = translateLocalazy('Cognitive')
                                        sections = e.sections.map(s => translateLocalazy(cognitivePdfParams[s].title))
                                        break
                                      case QuestionerType.SYMPTOM_SURVEY:
                                        const relativeDegree = translateLocalazy(getRelativeDegree(answer.questioner, answer))
                                        const respondent = ` ${getRelativeTitle(answer)} ${relativeDegree ? ' (' + translate(relativeDegree) + ')' : ''}`
                                        answerTitle = translateLocalazy('symptom survey') + respondent
                                        sections = e.sections.map(s => translateLocalazy(symptomSurveyPdfParams[s].title))
                                        break
                                    }
                                    const dateAnswered = dayjs(answer.updatedAt).format('DD/MM/YYYY HH:mm')
                                    return <div key={i}>
                                      <div className="flex justify-between pdf-export-title">
                                        <span>{answerTitle}</span>
                                        <span className="date-answered">{dateAnswered}</span>
                                      </div>
                                      <ul>
                                        {sections.map(s => <li key={s}>{s}</li>)}
                                      </ul>
                                    </div>
                                  })}
                                </div>}
                            <span
                              className="download-pdf-date">{formatDate(downloadInfo.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </article>))
              : (
                <article className="card_comment-box">
                  <div className="comment_header">
                    <div className="comment_header_title">{translate('no results')}</div>
                  </div>
                </article>
              )
            }
          </div>
      }
    </PatientSideHistoryLayout>
  )
}

export default PatientSidePDFSection
