import { ApplicationState } from '../store'
import { UserInstitution } from "../types/Common"
import store from "../configureStore"
import { InstitutionAccess, InstitutionDTO, InstitutionStatisticDTO } from "../sdk"

export const institutionConfig = (state: ApplicationState): InstitutionDTO | undefined => {
  const inst = state.auth.user.institution
  const institutions = state.institutions.all

  return institutions.find(e => e.id === inst)
}
export const institutionConfigById = (state: ApplicationState, id: string): InstitutionDTO | undefined => {
  const institutions = state.institutions.all

  return institutions.find(e => e.id === id)
}

export const institutionAccess = (state: ApplicationState): InstitutionAccess => {
  const config = institutionConfig(state)

  return config?.access || {
    cognitiveTest: false,
    symptomSurveyTest: false,
    anamnesisTest: false,
    sms: false,
    medstaffManagement: false,
    deletingPatients: false,
  }
}
export const getInstitutionsSelector = (state: ApplicationState): InstitutionDTO[] => state.institutions.all
export const getInstitutionStatisticSelector = (state: ApplicationState): InstitutionStatisticDTO[] => state.institutions.statistic

export const institutionName = (institution: string): string => {
  const institutions = store.getState().institutions.all

  return institutions.find(i => i.id === institution)?.name || institution
}

export const isGerasInstitution = (institution: string): boolean => {
  const name = institutionName(institution)

  return name === UserInstitution.GSAPP
}
